import { FC, useState } from 'react';
import fileIcon from '@/assets/icons/file.svg';
import deleteIcon from '@/assets/icons/delete.svg';
import attachIcon from '@/assets/icons/attach.svg';
import { Input, InputProps } from '../Input';
import clsx from 'clsx';
import './InputAttachment.scss';

interface InputAttachmentProps extends InputProps {
  acceptFiles: string[];
  file: File | null;
  onFileChange: (file: File | null) => void;
}

export const InputAttachment: FC<InputAttachmentProps> = (props) => {
  const { acceptFiles, file, onFileChange, className, ...rest } = props;
  const [fileError, setFileError] = useState('');

  const filterFile = (file: File) => {
    setFileError('');
    const isFormatValid = acceptFiles.some((format) => {
      const regex = new RegExp(format, 'gi');
      return regex.test(file.name);
    });
    const isSizeValid = 52428800 > file.size;

    if (isFormatValid && isSizeValid) {
      onFileChange(file);
    } else {
      onFileChange(null);
    }

    if (!isFormatValid) {
      setFileError(`Разрешены файлы только в формате ${acceptFiles.join(' ')}`);
    }

    if (!isSizeValid) {
      setFileError(
        `Максимальный объем прикрепляемого файла - 50 Мб. Хочешь прислать нам что-то потяжелее? Оставь ссылку на файл, мы разберемся`
      );
    }
  };

  return (
    <div className={clsx('input-attachment-root', className)}>
      <div className="input-attachment-root__input">
        <Input className="input-attachment-root__input-input" {...rest} />
        {!file && (
          <label className="input-attachment-root__input-file">
            <input
              type="file"
              value={''}
              onChange={(e) => filterFile(e.target.files![0])}
            />
            <img src={attachIcon} alt="" />
            <span>Прикрепить файл</span>
          </label>
        )}
      </div>
      {file && (
        <div className={'input-attachment-root__selected'}>
          <img alt="" src={fileIcon} />
          <span>{file.name}</span>
          <img alt="" src={deleteIcon} onClick={() => onFileChange(null)} />
        </div>
      )}
      {!!fileError && (
        <div className="input-attachment-root__error">{fileError}</div>
      )}
    </div>
  );
};
