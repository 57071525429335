import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { headerReducer } from './header';
import { homeReducer } from './home/reducer';
import { requestProjectReducer } from './requestProject/reducer';
import { sendMessageReducer } from './sendMessage/reducer';
import { sendResumeReducer } from './sendResume/reducer';
import { casesReducer } from './cases/reducer';
import { caseDetailsReducer } from './caseDetails/reducer';
import { blogsReducer } from './blogs/reducer';
import { blogDetailsReducer } from './blogDetails/reducer';
import { massMediaReducer } from './massMedia/reducer';
import { activeSectionsReducer } from './activeSections/reducer';
import { vacanciesReducer } from './vacancies/reducer';
import { vacancyDetailsReducer } from './vacancyDetails/reducer';

export const store = configureStore({
  reducer: {
    home: homeReducer,
    sendMessage: sendMessageReducer,
    sendResume: sendResumeReducer,
    requestProject: requestProjectReducer,
    header: headerReducer,
    cases: casesReducer,
    caseDetails: caseDetailsReducer,
    blogs: blogsReducer,
    blogDetails: blogDetailsReducer,
    massMedia: massMediaReducer,
    vacancies: vacanciesReducer,
    vacancyDetails: vacancyDetailsReducer,
    activeSections: activeSectionsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { useAppDispatch as useDispatch, useAppSelector as useSelector };
export * from './header';
