import clsx from 'clsx';
import { FC, InputHTMLAttributes, useEffect, useRef } from 'react';
import './Input.scss';

export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  inputType?: 'input' | 'textarea';
  error?: boolean;
  errorText?: string;
}

export const Input: FC<InputProps> = (props) => {
  const { inputType = 'input', error, errorText, className, ...rest } = props;
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    const textarea = textareaRef.current;

    if (textarea && textarea.textLength === 0) {
      textarea.style.height = 'auto';
    }
  });

  return (
    <div className={clsx('input-root', className)}>
      {inputType === 'input' && (
        <input
          className={clsx('input-root__input', {
            'input-root__input-error': !!error,
          })}
          {...rest}
        />
      )}
      {inputType === 'textarea' && (
        <textarea
          ref={textareaRef}
          rows={1}
          onInput={handleInput}
          className={clsx('input-root__input', {
            'input-root__input-error': !!error,
          })}
          {...rest}
        />
      )}
      {!!error && !!errorText && (
        <div className={clsx('input-root__error')}>{errorText}</div>
      )}
    </div>
  );
};
