import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../utils/api';
import { Vacancies, GetAllVacanciesResponse } from './interfaces';

export const getAllVacancies = createAsyncThunk<Vacancies, void>(
  'vacancies/getAllVacancies',
  async () => {
    const res: GetAllVacanciesResponse = await api.GET('vacancies/all');
    return res.data;
  }
);

export const getVacanciesBySlug = createAsyncThunk<Vacancies, string>(
  'vacancies/getVacanciesBySlug',
  async (slug) => {
    const res: GetAllVacanciesResponse = await api.GET(
      `vacancies/all?department_direction=${slug}`
    );
    return res.data;
  }
);
