import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSelector } from '@/store';
import { RefsContext } from '@/providers';
import { useBlockScroll, useRefRect } from '@/hooks';
import {
  SendResume,
  Accordion,
  ConditionalWrapper,
  AnimatedTitle,
  AnimatedBlock,
  Top,
} from '@/components';
import hh from '@/assets/icons/hhEmail.svg';

import styles from './Jobs.module.scss';
import { Footer } from '@/layouts';

export const Jobs: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const isScrollBlock = useBlockScroll();
  const { jobsRef } = useContext(RefsContext);
  const circleButtonRef = useRef<HTMLDivElement>(null);
  const [animationComplete, setAnimationComplete] = useState(false);
  const rect = useRefRect(circleButtonRef, [animationComplete]);
  const [isSendResume, setSendResume] = useState(false);
  const { jobs } = useSelector((store) => store.home.ru);
  const navigate = useNavigate();

  return (
    <div className={styles.jobs} ref={jobsRef}>
      <SendResume
        rect={rect}
        opened={isSendResume}
        onClose={() => setSendResume(false)}
      />
      <div className={styles.jobs__container}>
        <ConditionalWrapper
          condition={!!animate}
          wrap={(wrappedChildren) => (
            <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
          )}
        >
          <h2 className={styles.jobs__title}>ВАКАНСИИ</h2>
        </ConditionalWrapper>
        <ConditionalWrapper
          condition={!!animate}
          wrap={(wrappedChildren) => (
            <AnimatedBlock
              className={styles['jobs__anim-block']}
              onAnimationComplete={() => setAnimationComplete(true)}
            >
              {wrappedChildren}
            </AnimatedBlock>
          )}
        >
          <div className={styles.jobs__content}>
            <div>
              <Accordion
                data={jobs.map((j, i) => ({
                  id: i + 1,
                  title: j.name,
                  titleClassName: styles['jobs__list-item'],
                  stack: j.work_formats.map((l) => l.name),
                  stackStyle: 'slash',
                  body: '',
                  unopeneble: true,
                  link: `/vacancy/${j.slug}`,
                }))}
                lastRow={{
                  title: 'Показать все',
                  onClick: () => navigate('/vacancies/all'),
                }}
              />
              <div className={styles.jobs__buttons}>
                <div className={styles.jobs__questions}>
                  <span className={styles.jobs__any}>
                    ОСТАЛИСЬ ВОПРОСЫ? ПИШИТЕ
                  </span>
                  <a className={styles.jobs__hh} href="mailto:hr@evapps.ru">
                    <img alt="" src={hh} />
                  </a>
                </div>
              </div>
            </div>
            {isScrollBlock && (
              <div className={styles.jobs__footer}>
                <Footer />
                <Top className={styles['jobs__footer-top']} />
              </div>
            )}
          </div>
        </ConditionalWrapper>
      </div>
    </div>
  );
};
