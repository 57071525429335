import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { changeContentType, resetContentType } from '../../utils/api';
import { API_URL } from '../../utils/constants';
import { SendResume } from './interfaces';

export const reset = createAction('sendResume/reset');

export const sendResume = createAsyncThunk<boolean, SendResume>(
  'sendResume/sendMessage',
  async (params) => {
    const formData = new FormData();
    formData.append('vacancy_id', params.vacancy_id ?? '');
    formData.append('name', params.name ?? '');
    formData.append('tel', params.tel ?? '');
    formData.append('email', params.email ?? '');
    formData.append('other', params.other ?? '');
    formData.append('cover_letter', params.cover_letter ?? '');
    formData.append('files[0]', params.file ?? '');
    changeContentType();
    await fetch(API_URL + 'mail/send_summary', {
      method: 'POST',
      body: formData,
    });
    resetContentType();
    return true;
  }
);
