import { createReducer } from '@reduxjs/toolkit';
import { getAllVacancies, getVacanciesBySlug } from './actions';
import { Vacancies } from './interfaces';

export const initialState: Vacancies = {
  department_directions: [],
  vacancies: [],
};

export const vacanciesReducer = createReducer(initialState, (builder) => {
  builder.addCase(getAllVacancies.fulfilled, (state, action) => {
    state.vacancies = action.payload.vacancies;
    state.department_directions = action.payload.department_directions;
  });
  builder.addCase(getVacanciesBySlug.fulfilled, (state, action) => {
    state.vacancies = action.payload.vacancies;
    state.department_directions = action.payload.department_directions;
  });
});
