import React from 'react';
import { hydrate, render } from 'react-dom';
import { App } from './App';
import './assets/styles/main.scss';
import 'swiper/css';
import { store } from './store';
import { Provider } from 'react-redux';
import '@/assets/styles/fonts.css';

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error(
    'Root container was not found. It was not possible to mount the React application.'
  );
}

const Application = (
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

if (rootElement.hasChildNodes()) {
  hydrate(Application, rootElement);
} else {
  render(Application, rootElement);
}
