import { createAction, createReducer } from '@reduxjs/toolkit';
import { getVacancyBySlug } from './actions';
import { VacancyState } from './interfaces';

export const initialState: VacancyState = {
  vacancy: {
    id: 0,
    slug: '',
    name: '',
    required_experience: null,
    salary: null,
    description: '',
    work_formats: [],
    department_direction: null,
  },
};

export const clearVacancyDetails = createAction('cases/clearCaseDetails');

export const vacancyDetailsReducer = createReducer(initialState, (builder) => {
  builder.addCase(clearVacancyDetails, (state, action) => {
    return initialState;
  });
  builder.addCase(getVacancyBySlug.fulfilled, (state, action) => {
    state.vacancy = action.payload;
  });
});
