import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../utils/api';
import { GetVacancyBySlugResponse, VacancyDetails } from './interfaces';

export const getVacancyBySlug = createAsyncThunk<VacancyDetails, string>(
  'vacancies/getVacancyBySlug',
  async (slug) => {
    const res: GetVacancyBySlugResponse = await api.GET(`vacancy/${slug}`);
    return res.data;
  }
);
