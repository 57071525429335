import clsx from 'clsx';
import { FC } from 'react';
import { motion } from 'framer-motion';
import { AccordionBody } from './components/AccordionBody';
import { AccordionHeader } from './components/AccordionHeader';
import styles from './Accordion.module.scss';

export type AccordionItemType = {
  className?: string;
  bodyClassName?: string;
  titleClassName?: string;
  title: any;
  body?: React.ReactNode | string;
  id: number;
  unopeneble?: boolean;
  borderStyle?: string;
  stack?: string[];
  stackStyle?: 'circle' | 'slash';
  list?: string[];
  inlineList?: boolean;
  link?: string;
};

interface Props {
  icon?: boolean;
  item: AccordionItemType;
  opened: boolean;
  open?: (id: number) => void;
  animate?: boolean;
  hoverEnter?: (id: number) => void;
  hoverExit?: () => void;
}

export const AccordionItem: FC<Props> = ({
  opened,
  open,
  item,
  icon = true,
  animate = false,
  hoverEnter,
  hoverExit,
}) => {
  const {
    className,
    bodyClassName,
    titleClassName,
    title,
    body,
    id,
    stack,
    stackStyle = 'circle',
    list,
    inlineList = false,
    unopeneble,
    link,
  } = item;

  const handleOpen = () => {
    if (!open || unopeneble) {
      return;
    }

    open(id);

    if (hoverEnter) {
      hoverEnter(id);
    }
  };

  return (
    <motion.div
      className={clsx(styles.accordion, className, {
        [styles['accordion-border']]: id !== -1,
      })}
      initial={animate ? { x: -100, opacity: 0 } : false}
      animate={
        animate ? { x: 0, opacity: 1, transition: { duration: 0.5 } } : false
      }
      onMouseEnter={() => {
        if (hoverEnter) {
          hoverEnter(id);
        }
      }}
      onMouseLeave={hoverExit}
    >
      <div onClick={handleOpen}>
        <AccordionHeader open={opened} icon={icon} link={link}>
          <h3
            className={clsx(titleClassName, {
              [styles['accordion__head']]: stack && stack?.length !== 0,
            })}
          >
            {typeof title === 'string' && title}
            {typeof title === 'function' && title()}
          </h3>
          {stack && stack?.length !== 0 ? (
            stackStyle === 'circle' ? (
              <ul className={clsx(styles['stack'])}>
                {stack.map((t, id) => (
                  <li key={id}>{t}</li>
                ))}
              </ul>
            ) : (
              <div className={clsx(styles['stack'])}>
                {stack.map((t, id) => (
                  <span key={id}>
                    <span>{t}</span>
                    <span>{id !== stack.length - 1 ? '/' : null}</span>
                  </span>
                ))}
              </div>
            )
          ) : null}
        </AccordionHeader>
      </div>

      <AccordionBody
        isShow={opened}
        list={list}
        inlineList={inlineList}
        className={bodyClassName}
      >
        {body}
      </AccordionBody>
    </motion.div>
  );
};
