import React, { Suspense, useEffect } from 'react';
import { Route, Routes, BrowserRouter, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import {
  HomePage,
  CareerPage,
  NotFoundPage,
  Instruction,
  CaseDetailsPage,
  CasesPage,
  BlogDetailsPage,
  BlogPage,
  MassMediaPage,
  VacanciesPage,
  VacancyDetailsPage,
} from '@/pages';
import { RefsProvider, SwiperInstancesProvider } from '@/providers';
import { useDispatch } from '@/store';
import { getHomePageInfo } from './store/home/actions';
import { getAllActiveSections } from './store/activeSections/actions';
import { Cookies } from './components/Cookies/Cookies';

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
  }, [pathname]);

  return null;
};

export const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomePageInfo());
    dispatch(getAllActiveSections());
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <RefsProvider>
          <Suspense fallback="">
            <SwiperInstancesProvider>
              <Cookies />
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/career" element={<CareerPage />} />
                <Route path="/cases/:slug" element={<CasesPage />} />
                <Route path="/case/:slug" element={<CaseDetailsPage />} />
                <Route path="/blogs/:slug" element={<BlogPage />} />
                <Route path="/blog/:slug" element={<BlogDetailsPage />} />
                <Route path="/mass-media" element={<MassMediaPage />} />
                <Route path="/vacancies/:slug" element={<VacanciesPage />} />
                <Route path="/vacancy/:slug" element={<VacancyDetailsPage />} />
                <Route
                  path="/inventory/instruction.html"
                  element={<Instruction />}
                />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
              <ScrollToTop />
            </SwiperInstancesProvider>
          </Suspense>
        </RefsProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
};
