import { FC } from 'react';
import clsx from 'clsx';
import { useBlockScroll } from '@/hooks';

import { Footer } from './components/Footer';
import { Header } from './components/Header';
import styles from './Layout.module.scss';

export type PageTypes =
  | 'home'
  | 'career'
  | 'instruction'
  | 'cases'
  | 'case-details'
  | 'blogs'
  | 'blog-details'
  | 'mass-media'
  | 'vacancies'
  | 'vacancy-details';

interface Props {
  children: React.ReactNode;
  config?: {
    page: PageTypes;
  };
}

export const Layout: FC<Props> = ({ children, config }) => {
  const isBlockScroll = useBlockScroll();

  return (
    <>
      <Header {...config} />
      <div className={clsx(styles.container)}>{children}</div>
      {!isBlockScroll && <Footer />}
    </>
  );
};
