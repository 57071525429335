import { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { setHeaderMenu, useDispatch, useSelector } from '@/store';
import layoutStyles from '@/layouts/MainLayout/Layout.module.scss';
import { SocialNetwork } from '@/store/home/interfaces';

import { Menu } from './Menu/Menu';
import styles from './Header.module.scss';
import { RefsContext, SwiperInstancesContext } from '@/providers';
import { Navigator } from './Navigator/Navigator';
import { useRefRect, useJump, useBlockScroll, useMediaQuery } from '@/hooks';
import { SendResume } from '@/components/SendResume';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageTypes } from '../../Layout';

export interface HeaderProps {
  page?: PageTypes;
}

export const Header: React.FC<HeaderProps> = ({ page = 'home' }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const matchesLaptop = useMediaQuery('laptop');
  const isBlockScroll = useBlockScroll();
  const dispatch = useDispatch();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { headerInnerRef, headerRef, topRef, logoRef } =
    useContext(RefsContext);
  const jump = useJump();
  const { header } = useSelector((store) => store.home.ru);
  const { isHeaderOpened } = useSelector((store) => store.header);
  const isOpen = !!isHeaderOpened;
  const openedVariant = isHeaderOpened;
  const [init, setInit] = useState(false);
  const rect = useRefRect(buttonRef, [init]);
  const [isSendResume, setSendResume] = useState(false);
  const { scrollSwiper } = useContext(SwiperInstancesContext);

  function toHome() {
    if (location.pathname === '/') {
      if (isBlockScroll) {
        if (scrollSwiper) {
          scrollSwiper.allowSlidePrev = true;
          scrollSwiper.allowSlideNext = true;
          scrollSwiper?.slideTo(0);
        }
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      navigate('/');
    }
    closeMenu();
  }

  function openMenu(variant: 'home' | 'career') {
    if (isHeaderOpened !== variant) {
      dispatch(setHeaderMenu(variant));
    }
  }

  function closeMenu() {
    if (isOpen) {
      dispatch(setHeaderMenu(false));
    }
  }

  function renderLanguage() {
    return (
      <a className={clsx(styles['language'])} href="https://evapps.co/">
        ENG
      </a>
    );
  }

  function renderSocials() {
    return (
      <ul className={clsx(styles['header__soc'])}>
        {header.map((h: SocialNetwork) => (
          <li key={h.id}>
            <a target="_blank" href={h.url} rel="noreferrer">
              <img alt="" src={h.svg} />
            </a>
          </li>
        ))}
      </ul>
    );
  }

  useEffect(() => {
    setInit(true);
  }, []);

  return (
    <>
      <SendResume
        rect={rect}
        opened={isSendResume}
        onClose={() => setSendResume(false)}
      />
      <div ref={topRef} />
      <header
        className={clsx(styles.header)}
        ref={headerRef}
        id="header"
        data-opened={isOpen}
        onMouseLeave={closeMenu}
      >
        <div
          ref={headerInnerRef}
          className={clsx(styles['header__inner'], layoutStyles.container)}
        >
          <div className={styles.header__logo} ref={logoRef} onClick={toHome} />

          <Navigator page={page} onOpen={openMenu} onClose={closeMenu} />

          {isOpen && (
            <div className={clsx(styles['header__soc-wrap'])}>
              {renderSocials()}
            </div>
          )}

          {((page === 'home' && !isOpen) ||
            (page === 'career' && openedVariant === 'home') ||
            (page === 'instruction' && openedVariant === 'home') ||
            (page === 'home' && page === openedVariant) ||
            (page === 'home' && matchesLaptop) ||
            page === 'cases' ||
            page === 'blogs' ||
            page === 'case-details' ||
            page === 'blog-details' ||
            page === 'mass-media' ||
            page === 'vacancies' ||
            page === 'vacancy-details') && (
            <div className={clsx(styles.phone)}>
              <a href="tel:+7 (499) 653 59 22">
                <span>+7 (499)</span> 653 59 22
              </a>
            </div>
          )}

          {((page === 'career' && !isOpen) ||
            (page === 'instruction' && !isOpen) ||
            (page === 'home' && openedVariant === 'career') ||
            (page === 'instruction' && openedVariant === 'career') ||
            (page === 'instruction' && matchesLaptop) ||
            (page === 'career' && page === openedVariant) ||
            (page === 'career' && matchesLaptop)) && (
            <div className={clsx(styles.phone)}>
              <a href="tel:+7 (920) 788 16 80">
                <span>+7 (920)</span> 788 16 80
              </a>
            </div>
          )}

          {!isOpen && (
            <button
              ref={buttonRef}
              className={clsx(styles['header__btn'])}
              onClick={() => {
                if (
                  page === 'home' ||
                  page === 'cases' ||
                  page === 'blogs' ||
                  page === 'case-details' ||
                  page === 'blog-details' ||
                  page === 'mass-media' ||
                  page === 'vacancies' ||
                  page === 'vacancy-details'
                ) {
                  jump('request');
                } else {
                  setSendResume(true);
                }
              }}
            >
              {page === 'home' ||
              page === 'cases' ||
              page === 'blogs' ||
              page === 'case-details' ||
              page === 'blog-details' ||
              page === 'mass-media' ||
              page === 'vacancies' ||
              page === 'vacancy-details'
                ? 'Оставить заявку'
                : 'Отправить резюме'}
            </button>
          )}

          <div className={clsx(styles['language-wrap'])}>
            {renderLanguage()}
          </div>

          <button
            className={clsx(styles['menu'])}
            onClick={() => dispatch(setHeaderMenu(!isOpen))}
          >
            <span
              className={clsx(styles['menu__btn'], {
                [styles['menu__btn_opened']]: isOpen,
              })}
            ></span>
          </button>
        </div>
        <Menu
          isOpen={isOpen}
          close={() => dispatch(setHeaderMenu(false))}
          variant={openedVariant}
          page={page}
          openSendResume={() => setSendResume(true)}
          renderLanguage={renderLanguage}
          renderSocials={renderSocials}
        />
      </header>
    </>
  );
};
