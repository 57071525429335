import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from '@/store';
import { useMediaQuery } from '@/hooks';
import { requestProject, reset } from '@/store/requestProject/actions';
import { Button, Checkbox, Input, InputAttachment, Thanks, AnimatedBlock, AnimatedTitle, ConditionalWrapper } from '@/components';
import { RefsContext } from '@/providers';

import styles from './RequestProject.module.scss';
import { reachGoal } from '@/utils/yandexMetrika';

export const RequestProject: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const matchesTablet = useMediaQuery('tablet');
  const dispatch = useDispatch();
  const { isSended, loading } = useSelector((store) => store.requestProject);
  const { requestProjectRef } = useContext(RefsContext);

  const [checked, setChecked] = useState(false);

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [text, setText] = useState('');
  const [file, setFile] = useState<File | null>(null);

  const [phoneError, setPhoneError] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const phoneValid = phone.replace(/\D/g, '').length >= 6;
    const emailValid = email.length === 0 || (email.length && /\S+@\S+\.\S+/.test(email));

    e.preventDefault();
    if (checked && phoneValid && emailValid) {
      dispatch(
        requestProject({
          name,
          org: company,
          tel: phone,
          email: email,
          other: text,
          file: file ?? undefined,
        })
      );
      reachGoal(90838337, 'request-project');
      return;
    }
    if (!emailValid) {
      setEmailError(true);
    }
    if (!phoneValid) {
      setPhoneError(true);
    }
    if (!checked) {
      setCheckError(true);
    }
  };

  const onReset = () => {
    setName('');
    setCompany('');
    setEmail('');
    setPhone('');
    setText('');
    setFile(null);
    dispatch(reset());
  };

  const acceptFiles: string[] = JSON.parse(process.env.REACT_APP_ACCEPT_FILES || '[]');

  const renderButton = (mobile: boolean) => (
    <Button disabled={loading} type="submit" className={clsx({ [styles['requset-project__el-button']]: mobile })}>
      {loading ? 'Отправка...' : 'Отправить'}
    </Button>
  );

  return (
    <div className={styles['request-project']} ref={requestProjectRef}>
      <Thanks
        opened={isSended}
        title="Спасибо за вашу заявку"
        text="Мы внимательно ознакомимся с вашей заявкой и свяжемся с вами в кратчайшие сроки."
        onClose={() => onReset()}
        subtext="Команда Evapps"
      />
      <ConditionalWrapper condition={!!animate} wrap={(wrappedChildren) => <AnimatedTitle>{wrappedChildren}</AnimatedTitle>}>
        <h2 className={clsx(styles['request-project__title'])}>Оставить заявку на проект</h2>
      </ConditionalWrapper>
      <ConditionalWrapper condition={!!animate} wrap={(wrappedChildren) => <AnimatedBlock>{wrappedChildren}</AnimatedBlock>}>
        <form className={clsx(styles['request-project__form'])} onSubmit={onSubmit}>
          <Input className={styles['request-project__form__el-name']} type="text" placeholder="Ваше имя" value={name} onChange={(e) => setName(e.target.value)} maxLength={50} />
          <Input className={styles['request-project__form__el-company']} type="text" placeholder="Компания" value={company} onChange={(e) => setCompany(e.target.value)} maxLength={50} />
          <Input
            className={styles['request-project__form__el-email']}
            type="text"
            placeholder="E-mail"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(false);
            }}
            maxLength={50}
            error={emailError}
            errorText="Введите корректный email адрес"
          />
          <Input
            className={styles['request-project__form__el-tel']}
            type="tel"
            placeholder="Телефон*"
            value={phone}
            onChange={(e) => {
              const v = e.target.value.replace(/[^0-9 ()+]/g, '');
              setPhone(v);
              setPhoneError(false);
            }}
            maxLength={20}
            error={phoneError}
            errorText={phone.length === 0 ? 'Введите телефон' : 'Введите номер телефона целиком'}
          />

          <div className={styles['request-project__form__el-block']}>
            <InputAttachment
              className={styles['request-project__form__el-block-file']}
              type="text"
              placeholder="Подробности"
              onChange={(e) => setText(e.target.value)}
              value={text}
              maxLength={200}
              file={file}
              onFileChange={setFile}
              acceptFiles={acceptFiles}
            />
            {!matchesTablet && <div className={styles['request-project__form__el-block-btn']}>{renderButton(false)}</div>}
          </div>
          <Checkbox
            className={styles['request-project__form__el-checkbox']}
            checked={checked}
            onChange={(e) => {
              setChecked(e.target.checked);
              setCheckError(false);
            }}
            error={checkError}
            errorText="Дайте согласие"
          >
            Я даю согласие на{' '}
            <a
              className={clsx(styles['request-project__form__el-checkbox-link'], {
                [styles['request-project__form__el-checkbox-link__error']]: checkError,
              })}
              target="_blank"
              href="/backend/storage/policy.pdf"
            >
              обработку персональных данных
            </a>
          </Checkbox>
          {matchesTablet && renderButton(true)}
        </form>
      </ConditionalWrapper>
    </div>
  );
};
