import { Dispatch, SetStateAction } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';

import styles from './FilterButton.module.scss';

interface FilterButtonProps<T> {
  variant?: 'tag' | 'button';
  value: T;
  route: string;
  active: boolean;
  setActive: Dispatch<SetStateAction<Record<string, boolean>>>;
  className?: string;
  getSlug: (value: T) => string;
  getName: (value: T) => string;
  getAmount?: (value: T) => number;
}

export const FilterButton = <T,>({
  variant = 'tag',
  value,
  route,
  active,
  setActive,
  className,
  getSlug,
  getName,
  getAmount,
}: FilterButtonProps<T>) => {
  const navigate = useNavigate();
  const { slug } = useParams();

  const onClick = () => {
    if (!active && getSlug(value) !== null) {
      navigate(`/${route}/${getSlug(value)}`);
    } else {
      navigate(`/${route}/all`);
    }
    setActive((prev) => ({ [getSlug(value)]: !prev[getSlug(value)] }));
  };

  return (
    <button
      className={clsx(styles['filter-button'], className, {
        [styles['filter-button__tag']]: variant === 'tag',
        [styles['filter-button__button']]: variant === 'button',
        [styles['filter-button__tag_active']]: variant === 'tag' && active,
        [styles['filter-button__button_active']]:
          (variant === 'button' && active) ||
          (getName(value) === 'Все' && slug === 'all'),
      })}
      onClick={onClick}
      disabled={getAmount && getAmount(value) === 0 ? true : false}
    >
      {variant === 'tag' ? '#' : undefined}
      <span>{getName(value)}</span>
      {getAmount && <span>{getAmount(value)}</span>}
    </button>
  );
};
