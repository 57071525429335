import React, { FormEvent, forwardRef, useState } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import { FormLayout } from '@/layouts';
import { useMediaQuery } from '@/hooks';
import { reachGoal } from '@/utils/yandexMetrika';
import { useDispatch, useSelector } from '@/store';
import { reset, sendResume } from '@/store/sendResume/actions';

import styles from './SendResume.module.scss';
import { Button, Checkbox, Input, InputAttachment } from '../UI';
import { Thanks } from '../Thanks/Thanks';

interface Props {
  isModal?: boolean;
  vacancy_id?: number;
  rect?: DOMRect;
  opened?: boolean;
  onClose?: () => void;
}

export const SendResume = forwardRef<HTMLDivElement, Props>(
  ({ isModal = true, vacancy_id, rect, opened, onClose }, ref) => {
    const matchesTablet = useMediaQuery('tablet');
    const dispatch = useDispatch();
    const { isSended, loading } = useSelector((store) => store.sendResume);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [text, setText] = useState('');
    const [file, setFile] = useState<File | null>(null);

    const [checked, setChecked] = useState(false);

    const [phoneError, setPhoneError] = useState(false);
    const [checkError, setCheckError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const [requested, setRequeseted] = useState(false);

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
      const phoneValid = phone.replace(/\D/g, '').length >= 6;
      const emailValid =
        email.length === 0 || (email.length && /\S+@\S+\.\S+/.test(email));

      e.preventDefault();
      if (checked && phoneValid && emailValid) {
        setRequeseted(true);
        dispatch(
          sendResume({
            vacancy_id: vacancy_id ? String(vacancy_id) : '',
            name,
            tel: phone,
            email: email,
            other: text,
            cover_letter: '',
            file: file ?? undefined,
          })
        );
        reachGoal(90838337, 'send-resume');
        return;
      }
      if (!emailValid) {
        setEmailError(true);
      }
      if (!phoneValid) {
        setPhoneError(true);
      }
      if (!checked) {
        setCheckError(true);
      }
    };

    const onReset = () => {
      setName('');
      setEmail('');
      setPhone('');
      setText('');
      setFile(null);
      dispatch(reset());
      setPhoneError(false);
      setCheckError(false);
      setRequeseted(false);
      onClose && onClose();
    };

    const acceptFiles: string[] = JSON.parse(
      process.env.REACT_APP_ACCEPT_FILES || '[]'
    );

    const renderButton = (mobile: boolean) => (
      <Button
        disabled={loading}
        type="submit"
        className={clsx({ [styles['send-resume__el-button']]: mobile })}
      >
        {loading ? 'Отправка...' : 'Отправить'}
      </Button>
    );

    const formComponent = (
      <form className={styles['send-resume']} onSubmit={onSubmit}>
        <Input
          type="text"
          className={styles['send-resume__el-name']}
          placeholder="Ваше имя"
          maxLength={50}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          type="tel"
          className={styles['send-resume__el-tel']}
          placeholder="Телефон*"
          maxLength={20}
          value={phone}
          onChange={(e) => {
            const v = e.target.value.replace(/[^0-9 ()+]/g, '');
            setPhone(v);
            setPhoneError(false);
          }}
          error={phoneError}
          errorText={
            phone.length === 0
              ? 'Введите телефон'
              : 'Введите номер телефона целиком'
          }
        />
        <Input
          className={styles['send-resume__el-email']}
          placeholder="E-mail"
          type="text"
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError(false);
          }}
          maxLength={50}
          error={emailError}
          errorText="Введите корректный email адрес"
          value={email}
        />

        <div className={styles['send-resume__el-file']}>
          <InputAttachment
            className={styles['send-resume__el-file-file']}
            placeholder="Ваше сообщение"
            value={text}
            maxLength={1000}
            onChange={(e) => setText(e.target.value)}
            file={file}
            onFileChange={setFile}
            acceptFiles={acceptFiles}
            inputType="textarea"
          />
          {!matchesTablet && (
            <div className={styles['send-resume__el-file-btn']}>
              {renderButton(false)}
            </div>
          )}
        </div>

        <Checkbox
          className={styles['send-resume__el-checkbox']}
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
            setCheckError(false);
          }}
          error={checkError}
          errorText="Дайте согласие"
        >
          Я даю согласие на{' '}
          <a
            className={clsx(styles['send-resume__el-checkbox-link'], {
              [styles['send-resume__el-checkbox-link__error']]: checkError,
            })}
            target="_blank"
            href="/backend/storage/policy.pdf"
          >
            обработку персональных данных
          </a>
        </Checkbox>
        {matchesTablet && renderButton(true)}
      </form>
    );

    if (isModal) {
      return createPortal(
        <>
          <Thanks
            opened={!!isSended && requested}
            title="Спасибо за Отклик"
            text="Мы внимательно ознакомимся с вашим резюме и дадим вам обратную связь."
            onClose={() => onReset()}
            subtext="Команда Evapps"
          />
          <FormLayout
            rect={
              rect ?? {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                toJSON: () => null,
              }
            }
            opened={opened ?? false}
            title="ОТПРАВИТЬ РЕЗЮМЕ"
            onClose={() => onReset()}
          >
            {formComponent}
          </FormLayout>
        </>,
        document.querySelector('#modal')!
      );
    }

    return (
      <div ref={ref} className={styles['container']}>
        <Thanks
          opened={!!isSended && requested}
          title="Спасибо за Отклик"
          text="Мы внимательно ознакомимся с вашим резюме и дадим вам обратную связь."
          onClose={() => onReset()}
          subtext="Команда Evapps"
        />
        <h2 className={styles['container__title']}>Отправить резюме</h2>
        {formComponent}
      </div>
    );
  }
);
